import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { map, Observable, Subscriber } from "rxjs";
import { catchError } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import {
  IService,
  IServiceCreateRequest,
  IServiceCreateResponse,
  IServiceUpdateRequest
} from "./service-rest-api.types";
import { ISale } from "../sale";


@Injectable({
  providedIn: 'root',
})
export class ServiceRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Список идентификаторов услуг.
   * @param options     - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список идентификаторов услуг.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listId(options?: IGetListOption, accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/service`);
    return this.apiListId(urn, options, undefined);
  }

  /**
   * Подробная информация об услуге.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Получение подробной информации об услуге.
   *                В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<IService[]> {
    const urn: string = this.createUri(`/service/${ids.join(',')}`);
    return this.apiListInfo<IService>(
      urn,
      ids,
      (item: IService) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          serverUriAt: item.serverUriAt ? new Date(item.serverUriAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Создание услуги.
   * @param request - Данные формы для создания пользователя.
   * @description   - Метод создаёт новую запись об услуге.
   *                  Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public create(request: IServiceCreateRequest): Observable<IServiceCreateResponse | null> {
    const urn: string = this.createUri(`/service`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IServiceCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IServiceCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств услуги.
   * @param serviceId - Уникальный идентификатор услуги.
   * @param request - Данные формы редактирования пользователя.
   * @description - Метод изменяет часть свойств записи об услуге.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public update(serviceId: number, request: IServiceUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/service/${serviceId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }
}
