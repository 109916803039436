import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";

import { ApiConfig, IGetListOption, IGetListOptionFilter, IGetListOptionOrder } from "../api.types";
import { ApiService } from "../api.service";
import {
  IConsumer,
  IConsumerCreateRequest,
  IConsumerCreateResponse,
  IConsumerUpdateRequest,
  IConsumerUsername,
  IConsumerUsernameCreateRequest,
  IConsumerUsernameCreateResponse,
  IConsumerUsernameUpdateRequest
} from "./consumer-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class ConsumerRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Список идентификаторов потребителей.
   * @param options      - Опции лимитов, сортировки, фильтрации.
   * @param _accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список идентификаторов потребителей системы.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listId(options?: IGetListOption, _accessToken?: string): Observable<number[]> {
    const urn: string = this.createUri(`/consumer`);
    return this.apiListId(urn, options, undefined);
  }

  /**
   * Подробная информация о потребителе.
   * @param ids         - Идентификаторы через запятую.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Получение подробной информации о потребителе.
   *                В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listInfo(ids: number[], accessToken?: string): Observable<IConsumer[]> {
    const urn: string = this.createUri(`/consumer/${ids.join(',')}`);
    return this.apiListInfo<IConsumer>(
      urn,
      ids,
      (item: IConsumer) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          updateAt: item.updateAt ? new Date(item.updateAt) : undefined,
        });
      },
      accessToken,
    );
  }

  /**
   * Создание потребителя.
   * @param request - Данные формы для создания пользователя.
   * @description - Метод создаёт запись о потребителе.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public create(request: IConsumerCreateRequest): Observable<IConsumerCreateResponse | null> {
    const urn: string = this.createUri(`/consumer`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IConsumerCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IConsumerCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Конфликт. Уже существует потребитель с тем же самым идентификатором пользователя телеграм.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств потребителя.
   * @param tuserId - Уникальный идентификатор пользователя.
   * @param request - Данные формы редактирования пользователя.
   * @description - Метод изменяет часть свойства записи о потребителе.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public update(tuserId: number, request: IConsumerUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/consumer/${tuserId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление потребителя.
   * @param tuserId     - Уникальный идентификатор пользователя.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод выполняет удаление потребителя.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public delete(tuserId: number, accessToken?: string): Observable<void | null> {
    const urn = this.createUri(`/consumer/${tuserId}`);
    return this.apiDelete<void>(urn, accessToken);
  }

  /**
   * Список имён пользователей потребителя.
   * @param consumerId  - Уникальный идентификатор потребителя.
   * @param options     - Опции лимитов, сортировки, фильтрации.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод возвращает список имён пользователей потребителей.
   *                Метод доступен аутентифицированному пользователю.
   */
  public usernameList(consumerId: number, options?: IGetListOption, accessToken?: string): Observable<IConsumerUsername[]> {
    const headers: HttpHeaders = new HttpHeaders();
    let params: HttpParams = new HttpParams();
    let urn: string = this.createUri(`/consumer/${consumerId}/username`);

    if (accessToken) urn += `?accessToken=${accessToken}`;
    if (options) {
      if (options.limit) params = params.append('limit', `${options.limit.offset}:${options.limit.limit}`);
      if (options.by) {
        options.by.forEach((order: IGetListOptionOrder): void => {
          params = params.append('by', `${order.name}:${order.type}`);
        });
      }
      if (options.filter) {
        options.filter.forEach((filter: IGetListOptionFilter): void => {
          params = params.append('filter', `${filter.name}:${filter.type}:${filter.value}`);
        });
      }
    }
    const fnMap = (item: IConsumerUsername) => { // Функция конвертации свойств содержащих дату в объект даты.
      return Object.assign({}, item, {
        createAt: item.createAt ? new Date(item.createAt) : undefined,
      });
    };
    return this.http
      .get<IConsumerUsername[]>(urn, {
        observe: 'response', params,
        headers,
      })
      .pipe(
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<IConsumerUsername[]>) => (response as any).body.map(fnMap)),
        debounceTime(200),
      );
  }

  /**
   * Добавление пользователя потребителю.
   * @param consumerId - Уникальный идентификатор потребителя.
   * @param request    - Данные формы для создания пользователя потребителя.
   * @description - Метод создаёт имя пользователя для потребителя.
   *                Имя пользователя требуется для создания конфигурационного файла для каждой приобретённой
   *                пользователем услуги.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public usernameCreate(consumerId: number, request: IConsumerUsernameCreateRequest): Observable<IConsumerCreateResponse | null> {
    const urn: string = this.createUri(`/consumer/${consumerId}/username`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<IConsumerUsernameCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<IConsumerCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Имя пользователя или электронный адрес уже используется, пожалуйста придумайте другие.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Изменение свойств пользователя потребителя.
   * @param consumerId - Уникальный идентификатор потребителя.
   * @param usernameId - Уникальный идентификатор пользователя потребителя.
   * @param request    - Данные формы пользователя потребителя.
   * @description - Метод изменяет часть свойств пользователя потребителя.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public usernameUpdate(consumerId: number, usernameId: number, request: IConsumerUsernameUpdateRequest): Observable<void | null> {
    const urn: string = this.createUri(`/consumer/${consumerId}/username/${usernameId}`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .patch<void>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<void>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Удаление пользователя у потребителя.
   * @param consumerId  - Идентификатор потребителя.
   * @param usernameId  - Уникальный идентификатор пользователя потребителя.
   * @param accessToken - Токен доступа при аутентификации с доступом к токену.
   * @description - Метод выполняет удаление пользователя у потребителя.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public usernameDelete(consumerId: number, usernameId: number, accessToken?: string): Observable<void | null> {
    const urn: string = this.createUri(`/consumer/${consumerId}/username/${usernameId}`);
    return this.apiDelete<void>(urn, accessToken);
  }

  /**
   * Формирование имени пользователя для отображения.
   * Отображаемое имя зависит от того какие данные о пользователе введены.
   * @param username - Объект пользователя потребителя.
   */
  public usernamePrettyPrint(username: IConsumerUsername): string {
    let ret: string = '';

    if (username.name !== '') ret += username.name;
    if (username.email != '' && username.email.indexOf('@default.local') < 0) {
      if (ret !== '') ret += ' ';
      ret += `<${username.email}>`;
    }
    if (username.username != '') {
      if (!(username.username.length === 17 && username.username[0] === 'u')) {
        if (ret !== '') ret += ' - ';
        ret += username.username;
      }
    }
    if (ret === '') ret = '—';

    return ret;
  }
}
