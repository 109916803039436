<div class="flex flex-row justify-between px-[8px]
            w-full h-[34px] border border-bbGrayTableBorder -mb-[1px]
            text-[12px] leading-[32px]">

  <!-- Заголовок пагинатора. -->
  <div *ngIf="record > 0">Всего записей: {{record}}</div>
  <!-- /Заголовок пагинатора. -->

  <div>
    <ul class="flex flex-row">
      <!-- Первая страница. -->
      <li class="px-[5px] my-auto">
        <a
          *ngIf="count >= 1"
          (click)='first()'
          [ngClass]="{
                    'cursor-default': current === 1,
                    'cursor-pointer': current !== 1}"
          class="select-none min-w-[20px]"
        >
          <component-sprite-icon *ngIf="current !== 1" [name]="'first-10px-active'" [width]="12" [height]="12"></component-sprite-icon>
          <component-sprite-icon *ngIf="current === 1" [name]="'first-10px-passive'" [width]="12" [height]="12"></component-sprite-icon>
        </a>
      </li>
      <!-- /Первая страница. -->

      <!-- Предыдущая страница. -->
      <li class="px-[5px] my-auto">
        <a
          *ngIf="count >= 1"
          (click)='previous()'
          [ngClass]="{
                    'cursor-default': current === 1,
                    'cursor-pointer': current !== 1}"
          class="select-none min-w-[20px]"
        >
          <component-sprite-icon *ngIf="current !== 1" [name]="'previous-10px-active'" [width]="12" [height]="12"></component-sprite-icon>
          <component-sprite-icon *ngIf="current === 1" [name]="'previous-10px-passive'" [width]="12" [height]="12"></component-sprite-icon>
        </a>
      </li>
      <!-- /Предыдущая страница. -->

      <!-- До 3. -->
      <li *ngIf="count >= 1" class="px-[5px]">
        <a (click)='onSelectPage(1)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 1,
                     'cursor-pointer font-normal': current !== 1}"
           class="select-none block min-w-[20px] text-center">1</a>
      </li>
      <li *ngIf="count >= 2" class="px-[5px]">
        <a (click)='onSelectPage(2)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 2,
                     'cursor-pointer font-normal': current !== 2}"
           class="select-none block min-w-[20px] text-center">2</a>
      </li>
      <li *ngIf="count >= 3" class="px-[5px]">
        <a (click)='onSelectPage(3)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 3,
                     'cursor-pointer font-normal': current !== 3}"
           class="select-none block min-w-[20px] text-center">3</a>
      </li>
      <!-- /До 3. -->

      <!-- От 4 (до 11 или при количестве страниц меньше 6). -->
      <li *ngIf="count >= 4 && (count <= 11 || current < 6)" class="px-[5px]">
        <a (click)='onSelectPage(4)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 4,
                     'cursor-pointer font-normal': current !== 4}"
           class="select-none block min-w-[20px] text-center">4</a>
      </li>
      <li *ngIf="count >= 5 && (count <= 11 || current < 6)" class="px-[5px]">
        <a (click)='onSelectPage(5)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 5,
                     'cursor-pointer font-normal': current !== 5}"
           class="select-none block min-w-[20px] text-center">5</a>
      </li>
      <li *ngIf="count >= 6 && (count <= 11 || current < 6)" class="px-[5px]">
        <a (click)='onSelectPage(6)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 6,
                     'cursor-pointer font-normal': current !== 6}"
           class="select-none block min-w-[20px] text-center">6</a>
      </li>
      <li *ngIf="count >= 7 && (count <= 11 || current < 6)" class="px-[5px]">
        <a (click)='onSelectPage(7)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 7,
                     'cursor-pointer font-normal': current !== 7}"
           class="select-none block min-w-[20px] text-center">7</a>
      </li>
      <!-- /От 4 (до 11 или при количестве страниц меньше 6). -->

      <!-- От 8 до 11. -->
      <li *ngIf="count >= 8 && count <= 11" class="px-[5px]">
        <a (click)='onSelectPage(8)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 8,
                     'cursor-pointer font-normal': current !== 8}"
           class="select-none block min-w-[20px] text-center">8</a>
      </li>
      <!-- /От 8 до 11. -->

      <!-- От 9 до 10. -->
      <li *ngIf="count >= 9 && count <= 10" class="px-[5px]">
        <a (click)='onSelectPage(9)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 9,
                     'cursor-pointer font-normal': current !== 9}"
           class="select-none block min-w-[20px] text-center">9</a>
      </li>
      <li *ngIf="count >= 10 && count <= 10" class="px-[5px]">
        <a (click)='onSelectPage(10)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === 10,
                     'cursor-pointer font-normal': current !== 10}"
           class="select-none block min-w-[20px] text-center">10</a>
      </li>
      <!-- /От 9 до 10. -->

      <!-- Середина. -->
      <li
        *ngIf="count > 11 && current >= 6 && current < count - 5"
        class="px-[5px] select-none block text-center">
        <div (click)="toggleSpaceLeft()" class="cursor-pointer">...</div>
        <input-small-number *ngIf="spaceLeftToggle" [min]="1" [max]="count" [num]="current"
                            (response)="onSelectPage($event)"></input-small-number>
      </li>
      <li *ngIf="count > 11 && current >= 6 && current < count - 5" class="px-[5px]">
        <a (click)='onSelectPage(current - 1)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (current - 1),
                     'cursor-pointer font-normal': current !== (current - 1)}"
           class="select-none block min-w-[20px] text-center">{{current - 1}}</a>
      </li>
      <li *ngIf="count > 11 && current >= 6 && current < count - 5" class="px-[5px]">
        <a (click)='onSelectPage(current)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === current,
                     'cursor-pointer font-normal': current !== current}"
           class="select-none block min-w-[20px] text-center">{{current}}</a>
      </li>
      <li *ngIf="count > 11 && current >= 6 && current < count - 5" class="px-[5px]">
        <a (click)='onSelectPage(current + 1)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (current + 1),
                     'cursor-pointer font-normal': current !== (current + 1)}"
           class="select-none block min-w-[20px] text-center">{{current + 1}}</a>
      </li>
      <li
        *ngIf="count > 11 && current < count - 5"
        class="px-[5px] select-none block text-center">
        <div (click)="toggleSpaceRight()" class="cursor-pointer">...</div>
        <input-small-number *ngIf="spaceRightToggle" [min]="1" [max]="count" [num]="current"
                            (response)="onSelectPage($event)"></input-small-number>
      </li>
      <!-- /Середина. -->

      <!-- От 4 до 6 с конца. -->
      <li
        *ngIf="count >= 12 && current >= count - 5"
        class="px-[5px] select-none block text-center">
        <div (click)="toggleSpaceLeft()" class="cursor-pointer">...</div>
        <input-small-number *ngIf="spaceLeftToggle" [min]="1" [max]="count" [num]="current"
                            (response)="onSelectPage($event)"></input-small-number>
      </li>
      <li *ngIf="count > 11 && current >= count - 5" class="px-[5px]">
        <a (click)='onSelectPage(count - 6)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 6),
                     'cursor-pointer font-normal': current !== (count - 6)}"
           class="select-none block min-w-[20px] text-center">{{count - 6}}</a>
      </li>
      <li *ngIf="count > 11 && current >= count - 5" class="px-[5px]">
        <a (click)='onSelectPage(count - 5)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 5),
                     'cursor-pointer font-normal': current !== (count - 5)}"
           class="select-none block min-w-[20px] text-center">{{count - 5}}</a>
      </li>
      <li *ngIf="count > 11 && current >= count - 5" class="px-[5px]">
        <a (click)='onSelectPage(count - 4)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 4),
                     'cursor-pointer font-normal': current !== (count - 4)}"
           class="select-none block min-w-[20px] text-center">{{count - 4}}</a>
      </li>
      <li *ngIf="count > 11 && current >= count - 5" class="px-[5px]">
        <a (click)='onSelectPage(count - 3)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 3),
                     'cursor-pointer font-normal': current !== (count - 3)}"
           class="select-none block min-w-[20px] text-center">{{count - 3}}</a>
      </li>
      <!-- /От 4 до 6 с конца. -->

      <!-- Последние 3. -->
      <li *ngIf="count > 10" class="px-[5px]">
        <a (click)='onSelectPage(count - 2)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 2),
                     'cursor-pointer font-normal': current !== (count - 2)}"
           class="select-none block min-w-[20px] text-center">{{count - 2}}</a>
      </li>
      <li *ngIf="count > 10" class="px-[5px]">
        <a (click)='onSelectPage(count - 1)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === (count - 1),
                     'cursor-pointer font-normal': current !== (count - 1)}"
           class="select-none block min-w-[20px] text-center">{{count - 1}}</a>
      </li>
      <li *ngIf="count > 10" class="px-[5px]">
        <a (click)='onSelectPage(count)'
           [ngClass]="{
                     'cursor-default font-bold text-bbBlueRegular': current === count,
                     'cursor-pointer font-normal': current !== count}"
           class="select-none block min-w-[20px] text-center">{{count}}</a>
      </li>
      <!-- /Последние 3. -->

      <!-- Следующая страница. -->
      <li class="px-[5px] my-auto">
        <a
          *ngIf="count >= 1"
          (click)='next()'
          [ngClass]="{
                    'cursor-default': current === count,
                    'cursor-pointer': current !== count}"
          class="select-none min-w-[20px]"
        >
          <component-sprite-icon *ngIf="current !== count" [name]="'next-10px-active'" [width]="12" [height]="12"></component-sprite-icon>
          <component-sprite-icon *ngIf="current === count" [name]="'next-10px-passive'" [width]="12" [height]="12"></component-sprite-icon>
        </a>
      </li>
      <!-- /Следующая страница. -->

      <!-- Последняя страница. -->
      <li class="my-auto">
        <a
          *ngIf="count >= 1"
          (click)='last()'
          [ngClass]="{
                    'cursor-default': current === count,
                    'cursor-pointer': current !== count}"
          class="select-none block text-center"
        >
          <component-sprite-icon *ngIf="current !== count" [name]="'last-10px-active'" [width]="12" [height]="12"></component-sprite-icon>
          <component-sprite-icon *ngIf="current === count" [name]="'last-10px-passive'" [width]="12" [height]="12"></component-sprite-icon>
        </a>
      </li>
      <!-- /Последняя страница. -->
    </ul>
  </div>

</div>
