import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";

import { ApiConfig, IGetListOption } from "../api.types";
import { ApiService } from "../api.service";
import { ISale, ISaleCreateRequest, ISaleCreateResponse } from "./sale-rest-api.types";


@Injectable({
  providedIn: 'root',
})
export class SaleRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /**
   * Количество записей о продажах.
   * @param options      - Опции лимитов, сортировки, фильтрации, пагинации.
   * @description - Метод возвращает количество записей о продаже услуг потребителям.
   *                Метод доступен аутентифицированному пользователю.
   */
  public count(options?: IGetListOption): Observable<number> {
    const urn: string = this.createUri(`/sale/count`);
    return this.apiCount(urn, options, undefined);
  }

  /**
   * Список идентификаторов продаж.
   * @param options      - Опции лимитов, сортировки, фильтрации, пагинации.
   * @description - Метод возвращает список идентификаторов записей о продаже услуг потребителям.
   *                Метод доступен аутентифицированному пользователю.
   */
  public listId(options?: IGetListOption): Observable<number[]> {
    const urn: string = this.createUri(`/sale`);
    return this.apiListId(urn, options, undefined);
  }

  /**
   * Подробная информация о продаже.
   * @param ids         - Идентификаторы через запятую.
   * @description - Получение подробной информации о потребителе.
   *                В качестве идентификатора можно передавать один или несколько идентификаторов, разделённых запятой.
   *                Метод доступен аутентифицированному пользователю со статусом администратор.
   */
  public listInfo(ids: number[]): Observable<ISale[]> {
    const urn: string = this.createUri(`/sale/${ids.join(',')}`);
    return this.apiListInfo<ISale>(
      urn,
      ids,
      (item: ISale) => {
        return Object.assign({}, item, {
          createAt: item.createAt ? new Date(item.createAt) : undefined,
          begUtc: item.begUtc ? new Date(item.begUtc) : undefined,
          endUtc: item.endUtc ? new Date(item.endUtc) : undefined,
        });
      },
    );
  }

  /**
   * Новая продажа.
   * @param request - Данные формы для создания продажи.
   * @description - Метод создаёт запись о новой продажи услуги.
   *                Метод доступен аутентифицированному пользователю.
   */
  public create(request: ISaleCreateRequest): Observable<ISaleCreateResponse | null> {
    const urn: string = this.createUri(`/sale`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .post<ISaleCreateResponse>(urn, request, {
        observe: 'response',
        headers,
      })
      .pipe(
        map((response: HttpResponse<ISaleCreateResponse>) => response.body),
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 400:
              return this.handleError(
                error,
                'Передан не верный запрос.',
                false,
              );
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 402:
              return this.handleError(
                error,
                'Добавляется бесплатная услуга выходящая за рамки оплаченного периода либо у пользователя нет ' +
                'платных услуг и добавление бесплатной услуги не возможно.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 422:
              return this.handleError(
                error,
                'Указано количество месяцев продажи меньше минимального количества месяцев продажи услуги.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        })
      );
  }

  /**
   * Продажа, установка или снятие свойства «Оплачено».
   * @param saleId      - Уникальный идентификатор продажи.
   * @param isPaid      - Новое значение "свойства" «Оплачено», истина или ложь.
   * @param accessToken - Сессионный токен доступа.
   * @description - Метод выполняет изменение свойства «Оплачено» у продажи, устанавливает значение «Истина».
   *                Метод выполняет изменение свойства «Оплачено» у продажи, устанавливает значение «Ложь».
   *                Метод доступен аутентифицированному пользователю.
   */
  public paid(saleId: number, isPaid: boolean, accessToken?: string): Observable<void | null> {
    const headers: HttpHeaders = new HttpHeaders();
    let req: Observable<any>;
    let params: HttpParams;
    let urn: string;

    [params, urn] = [new HttpParams(), this.createUri(`/sale/${saleId}/paid`)];
    if (accessToken) urn += `?accessToken=${accessToken}`;
    switch (isPaid) {
      case true:
        req = this.http.put<void>(urn, {
          observe: 'response', params,
          headers,
        });
        break;
      case false:
        req = this.http.delete<void>(urn, {
          observe: 'response', params,
          headers,
        });
        break;
    }
    return req.pipe(
      catchError((error): Promise<never> => {
        switch (error.status) {
          case 401:
            return this.handleError(
              error,
              'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
              true
            );
          case 403:
            return this.handleError(
              error,
              'Доступ к методу запрещён.',
              true
            );
          case 404:
            return this.handleError(
              error,
              `Продажи с идентификатором ${saleId} не существует либо она удалена.`,
              true
            );
          case 422:
            return this.handleError(
              error,
              'Изменять значение "оплачено" для архивной продажи запрещено.',
              true
            );
          case 500:
            return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
          default:
            return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
        }
      }),
      debounceTime(200),
    );
  }

  /**
   * Архивация записи о продаже.
   * @param saleId      - Уникальный идентификатор продажи.
   * @param accessToken - Сессионный токен доступа.
   * @description - Метод выполняет изменение свойства «в архиве» у продажи, устанавливает значение «Истина».
   *                Архивировать можно только продажу с истёкшим сроком действия и продажу в состоянии «не оплачено».
   *                При попытке перенести в архив действующую продажу, вернётся код ошибки 409.
   *                Метод доступен аутентифицированному пользователю.
   */
  public archive(saleId: number, accessToken?: string): Observable<void | null> {
    const headers: HttpHeaders = new HttpHeaders();
    let urn: string = this.createUri(`/sale/${saleId}`);

    if (accessToken) urn += `?accessToken=${accessToken}`;
    return this.http
      .delete<void>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 401:
              return this.handleError(
                error,
                'Попытка доступа не авторизованным пользователем. Требуется аутентификация.',
                true
              );
            case 403:
              return this.handleError(
                error,
                'Доступ к методу запрещён.',
                true
              );
            case 404:
              return this.handleError(
                error,
                'Удаляемая запись отсутствует.',
                true
              );
            case 409:
              return this.handleError(
                error,
                'Продажа является действующей, перенесение в архив не возможно.',
                true
              );
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.');
            default:
              return this.handleError(error, 'Сервер вернул неожиданную ошибку: ' + error.status.toString());
          }
        }),
        map((response: HttpResponse<void>) => (response as any).body),
        debounceTime(200),
      );
  }
}
