/** Подробная информация о потребителе. */
export interface IConsumer {
  /** Уникальный идентификатор. */
  id: number;
  /** Дата и время создания записи. */
  createAt: string | Date | undefined;
  /** Дата и время обновления записи. */
  updateAt: string | Date | undefined;
  /** Разрешение доступа. */
  isAllowed: boolean;
  /** Блокировка доступа. */
  isBlock: boolean;
  /** Уникальный идентификатор пользователя телеграм. */
  telegramId: number;
  /** Имя пользователя телеграм. */
  telegramFirstName: string;
  /** Фамилия пользователя телеграм. */
  telegramLastName: string;
  /** Псевдоним пользователя телеграм. */
  telegramUsername: string;
  /** URI адрес аватара пользователя телеграм. */
  telegramPhotoUri: string;
  /** Фамилия Имя Отчество потребителя. */
  fio: string;
  /** Комментарий. */
  description: string;
}

/** Описание интерфейса данных для запроса создания потребителя. */
export interface IConsumerCreateRequest {
  /** Разрешение доступа. */
  isAllowed: boolean;
  /** Блокировка доступа. */
  isBlock: boolean;
  /** Уникальный идентификатор пользователя телеграм. */
  telegramId: number;
  /** Псевдоним пользователя телеграм. */
  telegramUsername: string;
  /** Фамилия Имя Отчество потребителя. */
  fio: string;
  /** Комментарий. */
  description: string;
}

/** Описание интерфейса данных ответа на запрос создания потребителя. */
export interface IConsumerCreateResponse {
  /** Уникальный идентификатор созданной сущности. */
  id: number;
}

/** Описание интерфейса данных для запроса редактирования потребителя. */
export interface IConsumerUpdateRequest {
  /** Разрешение доступа. */
  isAllowed: boolean;
  /** Блокировка доступа. */
  isBlock: boolean;
  /** Уникальный идентификатор пользователя телеграм. */
  telegramId: number;
  /** Псевдоним пользователя телеграм. */
  telegramUsername: string;
  /** Фамилия Имя Отчество потребителя. */
  fio: string;
  /** Комментарий. */
  description: string;
}

/** Интерфейс имён пользователей потребителя. */
export interface IConsumerUsername {
  /** Уникальный идентификатор пользователя потребителя. */
  id: number;
  /** Дата и время создания записи. */
  createAt: string | Date | undefined;
  /** Уникальный идентификатор потребителя. */
  consumerId: number;
  /** Включение или отключение пользователя. */
  isDisabled: boolean;
  /** Расширенный интерфейс. */
  isExtended: boolean;
  /** Уникальный UUID идентификатор пользователя. */
  uuid: string;
  /** ФИО пользователя. */
  name: string;
  /** Электронный адрес пользователя. */
  email: string;
  /** Имя пользователя или псевдоним, может создаваться случайно. */
  username: string;
  /** Тип VPN, константа: auto, openvpn, wireguard, shadowsocks и другие. */
  vpnType: string;
  /** Wireguard - Публичный ключ пользователя. */
  wgPublicKey: string;
  /** Wireguard - Общий ключ сервера и пользователя. */
  wgPresharedKey: string;
  /** Wireguard - IP адрес присваиваемый пользователю на устройстве пользователя. */
  wgIp: string;
  /** Массив строк через запятую в формате CIDR описывающий роутинг к сетям на стороне пользователя. */
  allowedNetwork: string;
  /** Интервал времени в секундах поддержания соединения со стороны сервера. */
  serverKeepalive: number;
  /** Интервал времени в секундах поддержания соединения со стороны клиента. */
  clientKeepalive: number;
  /** Xray - Порт сервера выделяемый пользователю. */
  xrayPort: number;
  /** Xray - Протокол шифрования VPN канала. Значение в документации к xray. */
  xrayProtocol: string;
  /** Xray - Протокол доступа к серверу, значения udp, tcp. Можно указывать через запятую. */
  xrayNetwork: string;
  /** Xray - Пароль пользователя, клиенты поддерживают максимум 20 символов. */
  xrayPassword: string;
  /** Xray - Метод шифрования трафика. Значение в документации к xray. */
  xrayMethod: string;
  /** Xray - Уникальный идентификатор порта. */
  xrayPortId: number;
  /** Xray - Доменное имя или IP. */
  xrayHost: string;
  /** Xray - Название хоста или сервера. */
  xrayHostName: string;
  /** Xray - Ссылка на конфигурацию, на странице QR код и URI для настройки клиента. */
  xrayUriConfiguration: string;
  /** Xray - Идентификатор пользователя VLESS, может быть любой строкой длиной менее 30 байт или допустимым UUID. */
  xrayId: string;
  /** Xray - Режим управления потоком, используется для выбора алгоритма XTLS. */
  xrayFlow: string;
  /** Xray - Уровень пользователя. */
  xrayLevel: number;
  /** Xray - Краткий Id пользователя на сервере. */
  xrayShortId: string;
  /** Xray - Публичный ключ сервера. */
  xrayKeyPub: string;
  /** Xray - Фейковый адрес сервера. */
  xraySni: string;
}

/** Запрос. Добавление пользователя потребителю. */
export interface IConsumerUsernameCreateRequest {
  /** Включение или отключение пользователя. */
  isDisabled: boolean;
  /** Расширенный интерфейс. */
  isExtended: boolean;
  /** ФИО пользователя. */
  name: string;
  /** Электронный адрес пользователя. */
  email: string;
  /** Имя пользователя или псевдоним, может создаваться случайно. */
  username: string;
  /** Тип VPN, константа: auto, openvpn, wireguard, shadowsocks и другие. */
  vpnType: string;
}

/** Ответ. Добавление пользователя потребителю. */
export interface IConsumerUsernameCreateResponse {
  /** Уникальный идентификатор созданной сущности. */
  id: number;
}

/** Запрос. Изменение смвойств пользователя потребителя. */
export interface IConsumerUsernameUpdateRequest {
  /** Включение или отключение пользователя. */
  isDisabled: boolean;
  /** Расширенный интерфейс. */
  isExtended: boolean;
  /** ФИО пользователя. */
  name: string;
  /** Электронный адрес пользователя. */
  email: string;
  /** Имя пользователя или псевдоним, может создаваться случайно. */
  username: string;
  /** Тип VPN, константа: auto, openvpn, wireguard, shadowsocks и другие. */
  vpnType: string;
  /** Wireguard - IP адрес присваиваемый пользователю на устройстве пользователя. */
  wgIp: string;
  /** Массив строк через запятую в формате CIDR описывающий роутинг к сетям на стороне пользователя. */
  allowedNetwork: string;
  /** Интервал времени в секундах поддержания соединения со стороны сервера. */
  serverKeepalive: number;
  /** Интервал времени в секундах поддержания соединения со стороны клиента. */
  clientKeepalive: number;
  /** Xray - Протокол шифрования VPN канала. Значение в документации к xray. */
  xrayProtocol: string;
  /** Xray - Протокол доступа к серверу, значения udp, tcp. Можно указывать через запятую. */
  xrayNetwork: string;
  /** Xray - Метод шифрования трафика. Значение в документации к xray. */
  xrayMethod: string;
}
