import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'minutes'
})
export class MinutesPipe implements PipeTransform {
  transform(value: number): string {
    const minutes:number = Math.floor(value / 60);
    const seconds:number = value - minutes * 60;

    return str_pad_left(minutes, '0' , 2) + ':' + str_pad_left(seconds, '0', 2);
    function str_pad_left(str:number, pad:string, length:number): string {
      return (new Array(length + 1).join(pad) + str).slice(-length);
    }
  }
}
