import { Component, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { IMenuSection } from "./menu-section.types";
import { AuthService } from "../../_services";


@Component({
  selector: 'component-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.scss']
})
export class MenuSectionComponent implements OnDestroy {
  private usersIsAdministratorSubscription?: Subscription = undefined;
  private isAdmin: boolean = false;

  public sections: IMenuSection[] = [];

  /**
   * Конструктор.
   * @param router          - Сервис роутинга.
   * @param authService     - Сервис аутентификации.
   */
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    this.usersIsAdministratorSubscription = this.authService.isAdministrator$.subscribe((v: boolean): void => {
      this.isAdmin = v;
    });
    this.makeSections();
  }

  /** Деструктор. */
  ngOnDestroy(): void {
    if (this.usersIsAdministratorSubscription) {
      this.usersIsAdministratorSubscription.unsubscribe();
      this.usersIsAdministratorSubscription = undefined;
    }
  }

  /** Создание объекта описывающего меню. */
  private makeSections(): void {
    this.sections.push({name: "потребители", link: ['', 'index', 'consumers'], isAdministrator: false});
    this.sections.push({name: "пользователи", link: ['', 'index', 'users'], isAdministrator: true});
    this.sections.push({name: "услуги", link: ['', 'index', 'services'], isAdministrator: true});
  }

  /** Возвращает состояние активности ссылки. */
  public isActive(link: string[]): boolean {
    const l: string = link.join('/');
    let ret: boolean = false;
    if (this.router.url === l) ret = true;
    return ret;
  }

  /**
   * Возвращает статус отображать раздел или не отображать.
   * @param section - Раздел.
   */
  public isShow(section: IMenuSection): boolean {
    let ret: boolean = false;

    switch (section.isAdministrator) {
      case true:
        if (this.isAdmin) ret = true;
        break;
      default:
        ret = true;
        break;
    }

    return ret;
  }
}
