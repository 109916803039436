import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ApiConfig } from "../api.types";
import { ApiService } from "../api.service";
import { IVersion } from "./core-rest-api.types";
import { ISaleCreateResponse } from "../sale";


@Injectable({
  providedIn: 'root',
})
export class CoreRestApiService extends ApiService {
  /**
   * Конструктор.
   * @param http - HTTP клиент.
   * @param config - Конфигурация API.
   */
  constructor(
    http: HttpClient,
    config: ApiConfig,
  ) {
    super(http, config);
  }

  /** Текущая версия приложения. */
  public version() : Observable<IVersion> {
    const urn: string = this.createUri(`/version`);
    const headers: HttpHeaders = new HttpHeaders();
    return this.http
      .get<IVersion>(urn, {
        observe: 'response',
        headers,
      })
      .pipe(
        catchError((error): Promise<never> => {
          switch (error.status) {
            case 500:
              return this.handleError(error, 'Сервер не в состоянии вернуть ответ.', true);
            default:
              return this.handleError(error, 'Неожиданная от сервера ошибка: ' + error.status, true);
          }
        }),
        map((response: HttpResponse<IVersion>) => response.body as any),
        map((item: IVersion) => {
          return Object.assign({}, item, {
            versionDbAt: item.versionDbAt ? new Date(item.versionDbAt) : undefined,
          });
        }),
      );
  }
}
