import { Component, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from "rxjs";


/**
 * Компонент таблицы для выбора страницы отображения данных.
 *
 * @param record - Количество записей всего.
 * @param count - Количество страниц.
 * @param current - Текущая страница.
 * @return Канал для передачи текущей выбранной страницы.
 */
@Component({
  selector: 'component-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss']
})
export class TablePaginatorComponent implements OnDestroy {
  private readonly keyEvent: Subject<KeyboardEvent> = new Subject<KeyboardEvent>(); // Внутренний канал нажатий клавиш.
  private keyEventSubscribe?: Subscription = undefined; // Подписка на канал событий нажатий клавиш.

  public spaceLeftToggle: boolean = false; // Отображение выпадающего элемента с ручным вводом номера страницы.
  public spaceRightToggle: boolean = false; // Отображение выпадающего элемента с ручным вводом номера страницы.

  @Input() record: number = 0; // Всего записей.
  @Input() count: number = 0; // Количество страниц.
  @Input() current: number = 0; // Текущая страница.
  @Output() public readonly pageChanged = new EventEmitter<number>(); // Канал возврата выбранной страницы.

  @HostListener('window:keydown', ['$event'])
  private handleKeyDown(event: KeyboardEvent) {
    this.keyEvent.next(event);
  }

  /**
   * Конструктор.
   */
  constructor() {
    this.keyEventSubscribe = this.keyEvent.pipe(
      // TODO: Добавить фильтрацию нажатых клавиш.
    )
      .subscribe((event: KeyboardEvent) => this.onKeyboardEvent(event));
  }

  /**
   * Деструктор.
   */
  ngOnDestroy(): void {
    if (this.keyEventSubscribe) {
      this.keyEventSubscribe.unsubscribe();
      this.keyEventSubscribe = undefined;
    }
  }

  /** Скрытие всех выпадающих списков ввода номера страницы. */
  private toggleSpaceOff(): void {
    this.spaceLeftToggle = false;
    this.spaceRightToggle = false;
  }

  /**
   * Изменение номера текущей страницы и передача его подписчикам.
   *
   * @param page - Новый номер страницы.
   */
  private changePage(page: number) {
    this.toggleSpaceOff();
    if (page > this.count) return;
    if (page < 1) return;
    this.current = page;
    this.pageChanged.next(page);
  }

  /** Событие нажатия клавиш клавиатуры. */
  private onKeyboardEvent(event: KeyboardEvent): void {
    if (event.ctrlKey && event.shiftKey && !event.altKey) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      switch (event.key) {
        case 'ArrowLeft':
          this.previous();
          break;
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowUp':
          this.first();
          break;
        case 'ArrowDown':
          this.last();
          break;
      }
    }
  }

  /**
   * Событие нажатия на иконку "предыдущая страница".
   */
  public previous(): void {
    this.toggleSpaceOff();
    if (this.current === 1) {
      return;
    }
    this.changePage(this.current - 1);
  }

  /**
   * Событие нажатия на иконку "следующая страница".
   */
  public next(): void {
    this.toggleSpaceOff();
    if (this.current === this.count) {
      return;
    }
    this.changePage(this.current + 1);
  }

  /**
   * Событие нажатия на иконку "первая страница".
   */
  public first(): void {
    this.toggleSpaceOff();
    if (this.current === 1) {
      return;
    }
    this.changePage(1);
  }

  /**
   * Событие нажатия на иконку "последняя страница".
   */
  public last(): void {
    this.toggleSpaceOff();
    if (this.current === this.count) {
      return;
    }
    this.changePage(this.count);
  }

  /**
   * Событие изменения номера страницы приходящее из HTML.
   * @param page - Новый номер страницы.
   */
  public onSelectPage(page: number): void {
    this.changePage(page);
    this.toggleSpaceOff();
  }

  /** Переключение отображения выпадающего элемента с ручным вводом номера страницы. */
  public toggleSpaceLeft(): void {
    this.spaceRightToggle = false;
    this.spaceLeftToggle = !this.spaceLeftToggle
  }

  /** Переключение отображения выпадающего элемента с ручным вводом номера страницы. */
  public toggleSpaceRight(): void {
    this.spaceLeftToggle = false;
    this.spaceRightToggle = !this.spaceRightToggle
  }
}
